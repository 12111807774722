<template>
  <v-container>
    <error-dialog
      message="Navigation error! Entity doesn't have parent"
      :showDialog.sync="navigationError"
    ></error-dialog>
    <v-row no-gutters>
      <v-col style="margin-top: -24px">
        <v-combobox
          class="pt-2"
          :loading="isLoading"
          :search-input.sync="search"
          :filter="customFilter"
          hide-no-data
          single-line
          hide-details
          clearable
          item-value="id"
          :label="searchHint"
          append-icon="mdi-database-search"
          return-object
          :color="colorTheme.comboBox"
          @click:clear="clear"
          @change="selectNew"
          @keypress="checkLength"
          :items="items"
          item-text="ItemName"
        >
        </v-combobox>
        <v-card-text v-if="minimumCharacters">
          Please type at least three characters
        </v-card-text>
        <v-card-text v-else-if="maximumCharacters">
          You've reached maximum number of characters
        </v-card-text>
        <v-card-text v-else-if="noResultsMessage">
          No results...
        </v-card-text>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import {
  maximumCharactersText,
  lettersAndNumbersCheck,
  validCharacterCheck,
  noResultsText,
} from "@/utils/search-texts.js";

export default {
  components: { ErrorDialog },
  data: () => ({
    noResults: false,
    isLoading: false,
    searchItems: [],
    numberOfLoadings: 0,
    navigationError: false,
    selectingNew: false,
  }),
  computed: {
    ...mapState("hierarchyMode", ["hierarchyTag"]),
    ...mapState("databaseSearch", [
      "searchString",
      "valueLimit",
      "minSearchLengthMain"
    ]),
    ...mapState("userConfig", ["colorTheme"]),
    items() {
      return this.searchItems.map((entry) => {
        const proposedItem = entry.name + " [" + entry.type + "]";

        const ItemName =
          proposedItem.length > this.valueLimit
            ? proposedItem.slice(0, this.valueLimit) + "..."
            : proposedItem;

        return Object.assign({}, entry, { ItemName });
      });
    },
    search: {
      get() {
        return this.searchString;
      },
      set(newValue) {
        if (newValue === undefined) {
          this.setSearchString(null);
        } else this.setSearchString(newValue);
      }
    },
    hierarchyMode() {
      return this.hierarchyTag.val;
    },
    currentRouteName() {
      return this.$route.name;
    },
    searchHint() {
      let hint = "Search " + this.hierarchyTag.tag + " Hierarchy";
      return hint;
    },
    minimumCharacters() {
      return lettersAndNumbersCheck(
        this.isLoading,
        this.search,
        this.minSearchLengthMain
      );
    },
    maximumCharacters() {
      return maximumCharactersText(this.search, 100);
    },
    noResultsMessage() {
      return (
        noResultsText(this.isLoading, this.search, this.items) &&
        this.noResults == true
      );
    },
  },
  watch: {
    search(val) {
      if (!this.$cookies.isKey("token")) {
        return;
      }
      if (validCharacterCheck(val, this.minSearchLengthMain) && !this.selectingNew) {
        this.filterEntities(val);
      } else {
        this.searchItems = [];
      }
    }
  },
  methods: {
    ...mapMutations("databaseSearch", ["setSearchString"]),
    ...mapMutations("searchEntities", ["cancelSearch"]),
    ...mapActions("searchEntities", ["searchEntities"]),
    ...mapActions("modifyEntity", ["refreshEntities"]),
    ...mapActions("feeSchedule", ["refreshSchedules"]),
    ...mapActions("directoryNavigation", ["jumpToNavigationItem"]),
    customFilter() {
      return true;
    },
    clear() {
      this.cancelSearch();
    },
    async filterEntities(filter) {
      this.numberOfLoadings++;
      this.isLoading = true;
      this.noResults = false;
      try {
        let result = await this.searchEntities({
          filter: filter,
          hierarchyMode: this.hierarchyMode
        });
        if (result) {
          if (result.length == 0) {
            this.noResults = true;
          }
          this.searchItems = result;
        }
      } catch (err) {
        console.log(err);
      }
      this.numberOfLoadings--;
      if (this.numberOfLoadings == 0) {
        this.isLoading = false;
      }
    },
    async selectNew(val) {
      if (val !== undefined && val !== null && val.id !== undefined) {
        this.selectingNew = true;
        let searchItem = val;
        searchItem.selectedFromSearch = true;
        let result = await this.jumpToNavigationItem(searchItem);
        if (result) {
          if (
            this.currentRouteName === "AddGroup" ||
            this.currentRouteName === "ModifyEntity"
          ) {
            await this.refreshEntities(val);
          } else if (this.currentRouteName === "FeeSchedules") {
            await this.refreshSchedules(val.id);
          }
        } else {
          this.navigationError = true;
        }
        this.selectingNew = false;
      }
    },
    checkLength() {
      if (
        this.search !== undefined &&
        this.search !== null &&
        this.search.length >= 99
      ) {
        this.setSearchString(this.search.slice(0, 99));
      }
    }
  }
};
</script>
