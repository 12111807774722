<template>
  <div id="hierarchy-picker" style="width: 260px">
    <section>
      <v-select
        :items="hierarchyTags"
        v-model="hierarchySelected"
        item-text="tag"
        item-value="val"
        append-icon="mdi-chevron-down"
        return-object
        single-line
        filled
        :color="colorTheme.dropDown"
        :item-color="colorTheme.dropDownItem"
      ></v-select>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import amplitude from 'amplitude-js'

export default {
  data: () => ({}),
  computed: {
    ...mapState("addAccountGroup", ["unsavedInputValue"]),
    ...mapState("hierarchyMode", ["hierarchyTag", "hierarchyTags"]),
    ...mapState("userConfig", ["colorTheme"]),
    hierarchySelected: {
      get() {
        return this.hierarchyTag;
      },
      set(newValue) {
        if (this.unsavedInputValue) {
          this.setUnsavedInputValueDialog(true);
          this.setNextHierarchyTag(newValue);
          return;
        }
        if (this.hierarchyTag.val != newValue.val) {
          this.setHierarchyTag(newValue);
          amplitude.getInstance().logEvent(`Fee Assignment - ${this.hierarchyTag.val} opened`);
          this.$router.push({
            name: "HierarchyTree",
            params: {
              hierarchy: this.hierarchyTag.address,
              id: "root"
            }
          });
          this.jumpToNavigationItem(null);
        }
      }
    }
  },
  methods: {
    ...mapMutations("addAccountGroup", [
      "setUnsavedInputValueDialog",
      "setNextHierarchyTag"
    ]),
    ...mapActions("hierarchyMode", ["setHierarchyTag"]),
    ...mapActions("directoryNavigation", ["jumpToNavigationItem"]),
  }
};
</script>
<style>
#hierarchy-picker > section > div > div > div::before {
  display: none !important;
}
#hierarchy-picker > section > div > div .v-input__slot::after {
  display: none !important;
}
#hierarchy-picker > section {
  margin-top: 25px;
}
#hierarchy-picker > section > div > div .v-input__slot > div > div > div > i { 
  margin-left: 10px;
}
#hierarchy-picker > section > div > div .v-input__slot {
  min-height: 32px;
  max-height: 32px;
  padding: 0 12px 0 12px;
  border-radius: 4px;
  background-color: #c7d6dd;
}
#hierarchy-picker > section > div > div > div > div .v-input__append-inner {
  margin-top: 4px;
}
#hierarchy-picker > section > div > div .v-input__slot > div > div > div { 
  font-size: 14pt;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 1.5px;
  overflow: unset;
}
</style>
