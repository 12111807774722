<template>
  <v-container class="pb-0">
    <v-row>
      <template :items="titleName">
        <div class="pl-3" v-html="headerTitle"></div>
      </template>
    </v-row>
    <v-row no-gutters>
      <v-col class="inline-fix">
        <div>
          <v-tabs
            v-model="entityTabs"
            :color="colorTheme.tabGroup"
            :background-color="colorTheme.tabGroupBackground"
          >
            <v-tab
              :color="colorTheme.tabItem"
              class="transform-off"
              small
              depressed
              tile
              @click="setTab('HierarchyTree')"
            >
              Hierarchy Tree
            </v-tab>
            <v-tab
              class="ml-2 transform-off"
              :color="colorTheme.tabItem"
              small
              depressed
              tile
              @click="setTab('FeeSchedules')"
            >
              Assign/Update Fees
            </v-tab>
            <v-tab
              :color="colorTheme.tabItem"
              class="transform-off"
              small
              depressed
              tile
              @click="setTab('ModifyEntity')"
            >
              Edit Entity
            </v-tab>
            <v-tab
              v-if="showAddGroup"
              :color="colorTheme.tabItem"
              class="transform-off"
              small
              depressed
              tile
              @click="setTab('AddGroup')"
            >
              Add Group
            </v-tab>
          </v-tabs>
        </div>
        <div v-show="showBillingTerms">
          <entity-overview></entity-overview>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import EntityOverview from "@/components/fee-schedule/EntityOverview.vue";

export default {
  components: {
    EntityOverview,
  },
  data: () => ({
    sleeveNameModel: "",
  }),
  computed: {
    ...mapGetters("directoryNavigation", ["getLastEntityInNavigation"]),
    ...mapState("directoryNavigation", ["navigation"]),
    ...mapState("feeSchedule", ["billingEntity"]),
    ...mapState("browseEntities", ["entities"]),
    ...mapState("directoryNavigation", ["navigation"]),
    ...mapState("hierarchyMode", ["hierarchyTag"]),
    ...mapState("userConfig", ["colorTheme"]),
    ...mapState("entityActions", ["tabs"]),
    entityTabs: {
      get() {
        return this.tabs;
      },
      set(newValue) {
        this.setTabs(newValue);
      }
    },
    titleName() {
      let title = [...this.navigation];
      return title;
    },
    showBillingTerms() {
      if (this.$route.name === "FeeSchedules") {
        return true;
      } else {
        return false;
      }
    },
    showAddGroup() {
      return (
        this.hierarchyTag.val === "ACCOUNT_GROUP" &&
        this.getParentType() === "AP_GROUP"
      );
    },
    headerTitle() {
      let entity = this.getLastEntityInNavigation;
      let type = "";
      let text = "";
      if (entity) {
        type = this.checkType(entity.type);
        text = entity.text;

        if (type.toLowerCase() === "sleeve") {
          if (text.includes("Model Sleeve")) {
            type = "Model Sleeve";
            text = text.replace("Model Sleeve ", "");
          } else {
            type = text;
            text = "";
          }
        }
      }
      if (text) {
        return `<h1><span style="font-weight:normal;">${type}${type !== "" ? ":" : ""} </span>${text}</h1>`;
      } else {
        return `<h1><span style="font-weight:normal;">${type} </span></h1>`;
      }
    },
  },
  methods: {
    ...mapMutations("entityActions", ["setTabs"]),
    ...mapActions("entityActions", ["setTab"]),
    getParentType() {
      if (this.navigation && this.navigation.length > 1) {
        return this.navigation[this.navigation.length - 2].type;
      } else {
        return false;
      }
    },
    checkType(type) {
      switch (type) {
        case "ACCOUNT_GROUP":
        case "AP_GROUP":
        case "OFFICERANGE_GROUP":
        case "GROUP":
          return "Group";
        case "ACCOUNT":
          return "Account";
        case "ADVISORY_FIRM":
          return "Firm";
        case "DEFAULT":
          return "Default";
        case "MANAGEMENT_FIRM":
          return "Management Firm";
        case "MODEL":
          return "Model";
        case "SLEEVE":
          return "Sleeve";
        case "ADVISORY_MANAGEMENT_FIRM":
          return "Adv/Mgmt Firm";
        default:
          return type;
      }
    }
  },
};
</script>
<style scoped>
.inline-fix {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}
.font {
  font-weight: normal !important;
}
.transform-off {
  text-transform: none;
}
</style>
