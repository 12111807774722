<template>
  <v-container class="pl-2 pt-2">
    <v-row no-gutters id="navigation-row">
      <div style="display: inline-block">
        <v-breadcrumbs class="ma-0" divider=">" :items="breadCrumbItems">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :disabled="item.disabled">
              <div :class="{'nav-item': true, 'opacity': addOpacity(item)}" @click="navigateTo(item)">
                {{ item.text }}
              </div>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  computed: {
    ...mapState("browseEntities", ["numberOfChildren"]),
    ...mapState("directoryNavigation", ["navigation"]),
    ...mapState("addAccountGroup", ["unsavedInputValue"]),
    currentRouteName() {
      return this.$route.name;
    },
    breadCrumbItems() {
      let arr = [...this.navigation];
      return arr;
    },
    leafNode() {
      return this.numberOfChildren == 0;
    },
  },
  watch: {
    navigation() {
      if (!this.$cookies.isKey("token")) {
        return;
      }
      if (this.navigation.length > 0) {
        this.setLastIdInNavigation(
          this.navigation[this.navigation.length - 1].id
        );
      }
      this.changePageTitle(this.$router.currentRoute);
    }
  },
  methods: {
    ...mapActions("pageTitle", ["changePageTitle"]),
    ...mapMutations("directoryNavigation", ["setLastIdInNavigation"]),
    ...mapMutations("addAccountGroup", [
      "setNextDirectoryNavigation",
      "setUnsavedInputValueDialog"
    ]),
    ...mapActions("directoryNavigation", ["jumpToNavigationItem"]),
    ...mapActions("modifyEntity", ["refreshEntities"]),
    ...mapActions("feeSchedule", ["refreshSchedules"]),
    ...mapActions("entityActions", ["setTab"]),
    addOpacity(prop) {
      return (
        this.navigation[this.navigation.length - 1].id === prop.id &&
        this.leafNode
      );
    },
    async navigateTo(item) {
      if (this.unsavedInputValue) {
        this.setNextDirectoryNavigation(item);
        this.setUnsavedInputValueDialog(true);
        return;
      }
      try {
        let oldNavLength = this.navigation.length;
        await this.jumpToNavigationItem(item);
        //this.$forceUpdate();

        if (item === null) {
          // Home button clicked
          await this.setTab("HierarchyTree");
        } else {
          if (this.currentRouteName === "ModifyEntity") {
            await this.refreshEntities(item);
          } else if (this.currentRouteName === "FeeSchedules") {
            await this.refreshSchedules(item.id);
          } else if (
            this.currentRouteName === "AddGroup" &&
            this.navigation.length < oldNavLength
          ) {
            await this.setTab("HierarchyTree");
          }
        }
      } catch (err) {
        console.log(err);
        await this.jumpToNavigationItem(null);
      }
    }
  }
};
</script>

<style scoped>
.nav-item {
  font-size: 14pt;
  color: rgba(0, 0, 0, 0.6);
}
.nav-item:hover {
  cursor: pointer;
}
.opacity {
  opacity: 0.39;
}
#navigation-row > div > ul {
  padding-left: 0 !important;
}
</style>
