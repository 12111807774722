<template>
  <v-container class="mt-0">
    <v-row class="my-0">
      <v-col cols="12" md="4" style="flex: none; max-width: 100%" class="pt-0">
        <p v-show="this.billingTermsVisible" style="font-size: 14pt">
          Billing Terms: <strong>{{ getBillingTerms() }}</strong>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    billingTermsVisible: false
  }),
  computed: {
    ...mapState("feeSchedule", ["billingEntity", "parentBillingEntity"]),
    ...mapState("userConfig", ["colorTheme"])
  },
  methods: {
    getBillingTerms() {
      let entity;
      if (this.billingEntity.type == "ACCOUNT") {
        entity = this.billingEntity;
      } else if (this.billingEntity.type == "SLEEVE") {
        entity = this.parentBillingEntity;
      } else {
        this.billingTermsVisible = false;
        return "";
      }

      if (!entity || entity.attributes == null) {
        this.billingTermsVisible = false;
        return "";
      }

      let billingEntitySpecProps = entity.attributes;
      let billingTermsSpecProp = billingEntitySpecProps.filter(
        (sp) => sp.attributeName == "Billing Type"
      );
      if (
        billingTermsSpecProp == null ||
        billingTermsSpecProp == undefined ||
        billingTermsSpecProp.length == 0 ||
        billingTermsSpecProp[0].attributeValue === ""
      ) {
        this.billingTermsVisible = false;
        return "";
      } else {
        this.billingTermsVisible = true;
        return billingTermsSpecProp[0].attributeValue;
      }
    }
  }
};
</script>
