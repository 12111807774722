<template>
  <div>
    <confirmation-dialog
      width="450px"
      :showDialog.sync="showConfirmationDialog"
      message="Are you sure you want to leave 'AddGroup'? Data you have entered will be lost."
      @confirm="confirm"
      @cancel="cancel"
    ></confirmation-dialog>
    <v-row no-gutters>
      <v-col>
        <v-container>
          <v-row no-gutters class="hierarchy-row">
            <div class="row-child">
              <span>
                <hierarchy-mode ref="hierarchyMode"></hierarchy-mode>
              </span>
              <span>
                <directory-navigation ref="navigation"></directory-navigation>
              </span>
            </div>
            <div id="search-box-wraper" class="search-box-stlye">
              <span v-show="currentRouteName !== 'AddGroup'">
                <database-search
                  :key="databaseSearchComponentKey"
                ></database-search>
              </span>
            </div>
          </v-row>
          <v-row style="margin-top: -35px;">
            <v-col cols="12" style="max-height: 11rem">
              <entity-actions></entity-actions>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <router-view></router-view>
            </v-col>
          </v-row>
          <custom-error
            ref="showCustomError"
            :ActionError.sync="ActionError"
            :ActionErrorMessage.sync="ActionErrorMessage"
            card-width="460"
            error-title="Browse Entities"
          ></custom-error>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

import DirectoryNavigation from "@/components/browse-entities/DirectoryNavigation.vue";
import HierarchyMode from "@/components/browse-entities/HierarchyMode.vue";
import DatabaseSearch from "@/components/browse-entities/DatabaseSearch.vue";
import EntityActions from "@/components/browse-entities/EntityActions.vue";
import CustomError from "../components/fee-schedule/CustomError.vue";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";
import amplitude from 'amplitude-js'

export default {
  components: {
    DirectoryNavigation,
    HierarchyMode,
    DatabaseSearch,
    EntityActions,
    CustomError,
    ConfirmationDialog,
  },
  data: () => ({
    ActionError: false,
    ActionErrorMessage: ""
  }),
  computed: {
    ...mapState("hierarchyMode", ["hierarchyTag"]),
    ...mapState("addAccountGroup", [
      "unsavedInputValueDialog",
      "nextRoute",
      "nextHierarchyTag",
      "nextDirectoryNavigation"
    ]),
    ...mapState("directoryNavigation", ["navigation", "lastIdInNavigation"]),
    ...mapState("databaseSearch", ["databaseSearchComponentKey"]),
    showConfirmationDialog: {
      get() {
        return this.unsavedInputValueDialog;
      },
      set(newValue) {
        this.setUnsavedInputValueDialog(newValue);
      }
    },
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods: {
    ...mapMutations("addAccountGroup", [
      "setUnsavedInputValueDialog",
      "setUnsavedInputValue",
      "setNextRoute",
      "setNextHierarchyTag",
      "setNextDirectoryNavigation",
      "setResetFormValue"
    ]),
    ...mapMutations("directoryNavigation", ["setNavigationSelected"]),
    ...mapActions("entityActions", [
      "returnTabName",
      "setDefaultRoute",
      "setTab"
    ]),
    ...mapActions("directoryNavigation", ["getSelectionId"]),
    ...mapActions("hierarchyMode", ["setHierarchyTag"]),
    refreshCustomError(error) {
      this.$refs.showCustomError.refresh(error);
    },
    async confirm() {
      await this.setResetFormValue(true);
      if (this.nextDirectoryNavigation !== null) {
        this.$refs.navigation.navigateTo(this.nextDirectoryNavigation);
        this.setNextDirectoryNavigation(null);
      }
      if (this.nextHierarchyTag !== "") {
        this.$refs.hierarchyMode.hierarchySelected = this.nextHierarchyTag;
        this.setNextHierarchyTag("");
      }
      if (this.nextRoute !== "") {
        const id = await this.getSelectionId();
        this.$router.push({
          name: this.nextRoute,
          params: {
            hierarchy: this.hierarchyTag.address,
            id: id
          }
        });
        this.setNavigationSelected(null);
        this.setNextRoute("");
      }
    },
    cancel() {
      this.setTab("AddGroup");
      this.setNextRoute("");
      this.setNextHierarchyTag("");
      this.setHierarchyTag({
        tag: "",
        val: "",
        address: ""
      });
      this.setHierarchyTag({
        tag: "Group/Account",
        val: "ACCOUNT_GROUP",
        address: "account-group"
      });
      this.setNextDirectoryNavigation(null);
      this.showConfirmationDialog = false;
    }
  },
  async mounted() {
    let tabSelected = await this.returnTabName();
    amplitude.getInstance().logEvent(`Fee Assignment - Tab Selected [${tabSelected}]`);
    if (tabSelected) {
      if (
        this.$route.name == this.$route.matched[0].name &&
        (this.$route.params.hierarchy != this.hierarchyTag.address ||
          this.$route.params.id != this.lastIdInNavigation)
      ) {
        this.$router.replace({
          name: tabSelected,
          params: {
            hierarchy: this.hierarchyTag.address,
            id: this.lastIdInNavigation
          }
        });
      }
    } else {
      console.log("Error with setting route");
      this.setDefaultRoute();
    }
  }
};
</script>
<style>
#search-box-wraper > span > div > .row > div > div {
  margin-top: -16px;
}
#search-box-wraper input {
  font-size: 14pt;
}
#search-box-wraper label {
  font-size: 14pt;
}
#search-box-wraper > span > div {
  height: 48px !important;
}
.hierarchy-row {
  height: 135px;
  display: flex;
  justify-content: space-between;
}
.row-child {
  display: flex;
  margin-left: 12px;
}
.search-box-stlye {
  width: 400px;
  margin: auto;
  margin-right: 0;
}
</style>
